import cookie from 'js-cookie'
import {
  AnnounceAnswerCookieForm,
  AnnounceCreateCookieForm,
} from 'reducers/announce/announce.types'
import { Cookies } from 'constants/cookie'
import { definitions } from 'api/generated/masked_ads'

export const updateCookieForm = (
  form: definitions['AdSearchPreferencesResponse']
): void => {
  const _form = {
    whoIsSeeker: form.whoIsSeeker,
    whoLookingFor: form.whoLookingFor,
    minAge: form.minAge,
    maxAge: form.maxAge,
    location: form.location,
    tags: form.tags,
  }
  cookie.set(Cookies.announceSettingsForm, JSON.stringify(_form), {
    expires: 14,
  })
}

export const updateAnnounceCreateForm = (
  form: AnnounceCreateCookieForm
): void => {
  const _form: AnnounceCreateCookieForm = {
    whoIsSeeker: form.whoIsSeeker,
    whoLookingFor: form.whoLookingFor,
    tags: form.tags,
    location: form.location,
    text: form.text,
    birthDate: form.birthDate,
    email: form.email,
  }

  cookie.set(Cookies.announceCreateForm, JSON.stringify(_form), {
    expires: 14,
  })
}

export const updateAnnounceAnswerCookie = (
  value: AnnounceAnswerCookieForm
): void => {
  cookie.set(Cookies.announceAnswerForm, JSON.stringify(value), {
    expires: 14,
  })
}

export const updateUrlPageCookie = (value?: string): void => {
  cookie.set(Cookies.urlPage, JSON.stringify(value), {
    expires: 14,
  })
}

import cookies from 'js-cookie'
import { AsyncAction, AsyncThunkAction } from 'actions/actions.types'
import { Cookies } from 'constants/cookie'
import { NodeHeaders } from 'server/NodeHeaders'
import { Paths } from 'components/components.paths'

import {
  AnnounceFormUpdateWithoutLocation,
  AnnounceListFindApi,
} from 'reducers/announce/announce.types'
import { AppDispatchNext, AppGetState } from 'actions/actions.typesNext'
import { convertLocation } from 'functions/convertLocation'
import { definitions } from 'api/generated/masked_ads'
import { fetchAdsAnnounceListApi } from 'api/announce/searchAnnounceListApi'
import { fetchExtendedLocationAction } from 'actions/locationAction'
import {
  findParameters,
  isNotEqual,
  isValidParams,
} from 'actions/announce.function'
import {
  markedInvalidParamsAction,
  resetInvalidParamsAction,
  updateFormWithoutLocationAction,
} from 'actions/announce/announceSettingsFormAction'
import { replace } from 'actions/system/router'
import { updateCookiesAction } from 'actions/system/setBootstrapDataAction'
import { updateUrlPageCookie } from 'client/updateCookieForm'

export const FETCH_ANNOUNCE_LIST = 'FETCH_ANNOUNCE_LIST'

interface SearchAnnounceListAction
  extends AsyncAction<definitions['AdListResponse']> {
  type: typeof FETCH_ANNOUNCE_LIST
  offset: number
}

export const searchAnnounceListAction =
  (headers?: NodeHeaders) =>
  async (dispatch: AppDispatchNext, getState: AppGetState) => {
    const {
      router,
      announceList: { storePage },
      system: { languagesLocalesString },
      announceForm: { defaultForm },
      dictionary: { tags },
    } = getState()

    const params = findParameters({
      path: router.location?.pathname,

      languagesLocalesString,
    })

    const isParamsValid = params && isValidParams(params, tags)
    const isNotEqualForm = params && isNotEqual(params, defaultForm)

    if (params && isParamsValid) {
      if (isNotEqualForm || params.page !== storePage) {
        dispatch(resetInvalidParamsAction())

        if (isNotEqualForm) {
          const _tags = tags.filter(({ id }) => params.tags.includes(id))

          const form: AnnounceFormUpdateWithoutLocation = {
            whoIsSeeker: params.whoIsSeeker,
            whoLookingFor: params.whoLookingFor,
            minAge: params.minAge,
            maxAge: params.maxAge,
            location: params.location,
            tags: _tags,
          }

          dispatch(
            updateFormWithoutLocationAction({
              ...form,
            })
          )

          if (
            convertLocation(params.location) !== defaultForm.location?.location
          ) {
            await dispatch(
              fetchExtendedLocationAction(
                convertLocation(params.location),
                headers
              )
            )
          }
        }

        const tagsStringIds = tags
          .filter(({ id }) => params.tags.includes(id))
          .map(({ value }) => value)

        const _params: AnnounceListFindApi = {
          ...params,
          tags: tagsStringIds,
        }

        await dispatch({
          type: FETCH_ANNOUNCE_LIST,
          promise: () => fetchAdsAnnounceListApi(_params, headers),
          offset: params.offset,
        })

        return
      }

      if (params && !isParamsValid) {
        return dispatch(markedInvalidParamsAction())
      }

      return Promise.resolve()
    }

    return Promise.resolve()
  }

export const CLEAR_ANNOUNCES_LIST = 'CLEAR_ANNOUNCES_LIST'

interface ClearAnnouncesListAction {
  type: typeof CLEAR_ANNOUNCES_LIST
}

export const clearAnnouncesListAction = (): ClearAnnouncesListAction => ({
  type: CLEAR_ANNOUNCES_LIST,
})

export const saveUrlPageToCookieAction =
  (): AsyncThunkAction<unknown> => (dispatch, getState) => {
    const pathname = getState().router?.location?.pathname

    if (pathname) {
      updateUrlPageCookie(pathname)
      dispatch(updateCookiesAction(Cookies.urlPage, pathname))
    }
  }

export const findRedirectUrlPageCookieAction =
  (): AsyncThunkAction<unknown> => (dispatch) => {
    const urlPage = cookies.getJSON(Cookies.urlPage)

    if (urlPage) {
      dispatch(replace(urlPage, 'cookie url page'))
    } else {
      dispatch(replace(Paths.index, 'cookie index'))
    }
  }

export type AnnounceListTypes =
  | ClearAnnouncesListAction
  | SearchAnnounceListAction
